import React from 'react';
import { graphql } from 'gatsby';
import ExploreMenu from '@components/fixed/exploreMenu';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';

const AboutPageTemplate = ({ data: { page } }) => {
  const { modules, seo, reverseHeader } = page || {};

  return (
    <Layout reverseHeader={reverseHeader}>
      <PageMeta {...seo} />

      {modules && <ModuleZone {...modules} />}

      <ExploreMenu />
    </Layout>
  );
};

export default AboutPageTemplate;

export const pageQuery = graphql`
  query standardAboutPageQuery($slug: String!) {
    page: sanityAboutPage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      reverseHeader
      modules {
        ...AboutModules
      }
    }
  }
`;
